import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";

import Header from "../components/common/header";
import CommonFooter from "../components/common/footer";
import UseCaseSider from "../components/useCases/use-cases-sider";

import UseCasesContentPage from "../components/useCases/content";

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>DayViewer Planner Use Cases</title>
      <meta
        name="description"
        content="What makes DayViewer so useful as a service, here are many of the ways you could use our online planner to help your business achieve more."
      />
    </Helmet>
    <Layout>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <UseCaseSider />
        <UseCasesContentPage />
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);
