import React from "react";
import { Layout } from "antd";
const { Content } = Layout;

class UseCasesContentPage extends React.Component {
  state = {};
  render() {
    return (
      <Content>
        <div className="content">
          <h1>Use Cases for DayViewer</h1>
          <p>
            DayViewer can be used in multiple ways so we created this section
            just to assist and explain how it could be used to help you be more
            productive in various scenarios.
          </p>
          <p>
            Open the side menu to see typical use cases of DayViewer Online
            Planner
          </p>
          <br />
          <br />
          <div
            style={{
              textAlign: "center",
              padding: 20,
            }}
          >
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-business-calendar-640.webp"
              ></source>
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-business-calendar-640.jpg"
              ></source>
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-business-calendar-640.jpg"
                alt="Use Cases Business, Personal, Study"
                title="Use Cases Business, Personal, Study"
              />
            </picture>
          </div>
        </div>
      </Content>
    );
  }
}

export default UseCasesContentPage;
